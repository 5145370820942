<template>
  <div id="Name">
    <van-nav-bar :fixed="true" placeholder :title="$t('Personal.name.ModifyNickname')" left-arrow
      @click-left="onClickLeft" />
    <div class="wrap-input">
      <van-notice-bar color="#000" background="var(--bg)">
        {{ $t('Personal.name.yourNickname') }}
      </van-notice-bar>
      <van-field label-align="right" colon v-model="Name" :label="$t('Personal.name.nickname')"
        :placeholder="$t('Personal.name.pleaseEnterNickname')" />
    </div>
    <div class="button-confirm">
      <van-button size="normal" type="default" block @click="ButtomEvent()">{{
      $t('Personal.name.Modify')
    }}</van-button>
    </div>
  </div>
</template>

<script>
import { GET_USERINFOUNEW, userinfo, editUserInfo } from '@/api/use'
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  NoticeBar,
  Field,
  Toast,
} from 'vant'

export default {
  name: 'Name',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [Toast.name]: Toast,
  },
  data() {
    return {
      title: "$t{'Personal.name.ModifyNickname'}",
      Name: '',
      userInfo: {}
    }
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    //  提交的
    ButtomEvent() {
      if (this.Name != '') {
        Toast.loading({
          forbidClick: true,
          duration: 0,
        })
        editUserInfo({ username: this.Name }).then((res) => {
          const { ret, msg, data } = res.data
          if (ret === 1) {
            GET_USERINFOUNEW();
            Toast(msg);
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000);
          } else {
            Toast(msg)
          }
        }).catch((e) => {
          Toast.clear()
        })
      } else {
        Toast(this.$t('Personal.name.phoneNew'))
      }
    },
    async getUserInfo() {
      const {
        data: { ret, data }
      } = await userinfo()
      if (ret === 1) {
        this.Name = data.username;
        this.userInfo = data;
        Toast.clear();
      } else {
        Toast.clear();
      }
    },
  },
  created() {
    this.getUserInfo();
    // this.Name = this.module.PersonalEvnet()[1].data.player.nickname
  },
}
</script>

<style lang="less" scoped>
#Name {
  width: 100%;
  height: 100%;




  .van-cell {
    margin-bottom: 1.5625rem;
    background-color: var(--bg);

    .van-field__label {
      color: var(--sizeColor);
    }

    .van-field__control {
      color: var(--theme);
    }

    .van-cell__title {
      >span {
        font-size: 0.875rem;
      }
    }
  }

  .van-cell::after {
    border-bottom: none;
  }

  .button-confirm {
    width: 100%;

    .van-button {
      width: 90%;
      margin: 0 auto;
      border-radius: 0.2rem;
      // background-color: #2f3848;
      background-color: #ff0000;
      color: #fff;

      // color: #ffffff;
    }
  }
}

/deep/ .van-nav-bar__content {
  background: #ff0000;

  .van-icon-arrow-left,
  .van-nav-bar__title {
    color: #fff;
  }
}

.wrap-input {
  background: #fff;
}
</style>